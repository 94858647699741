ion-tab-bar {
  background: var(--x-color-gray-10) !important;
  box-shadow: 15px 10px 15px 10px rgb(var(--template-color-primary-rgb), 0.2);
  justify-content: center;
  align-items: center;
  gap: 50px;
  flex-shrink: 0;
  border-radius: 32px;
  background: var(--template-color-on-primary);
  height: 65px;
  border: none;
  position: absolute;
  width: 100%;
  bottom: 0;

  ion-tab-button {
    background: transparent;
  }
}
