// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  --ion-font-family: U8, sans-serif;

  // Primary
  --x-color-primary: #328C90;
  --x-color-primary-shade: #9b081f;
  --x-color-on-primary: #ffffff;
  --x-color-on-primary-shade: #ffffff;
  // Secondary
  --x-color-secondary: #2c7dda;
  --x-color-secondary-shade: #2262ab;
  --x-color-on-secondary: #ffffff;
  --x-color-on-secondary-shade: #ffffff;
  // Accent
  --x-color-accent: #328C90;
  --x-color-accent-shade: #8d1c2d;
  --x-color-on-accent: #ffffff;
  --x-color-on-accent-shade: #ffffff;
  // Surface
  --x-color-surface: #ffffff;
  --x-color-surface-shade: #f7f7f7;
  --x-color-on-surface: #1a1a1a;
  --x-color-on-surface-shade: #1a1a1a;
  // Success
  --x-color-success: #75e28d;
  --x-color-success-shade: #59be6f;
  --x-color-on-success: #1a1a1a;
  --x-color-on-success-shade: #ffffff;
  // Warning
  --x-color-warning: #ffdc66;
  --x-color-warning-shade: #d4b132;
  --x-color-on-warning: #1a1a1a;
  --x-color-on-warning-shade: #ffffff;
  // Danger
  --x-color-danger: #ff5151;
  --x-color-danger-shade: #cb3f3f;
  --x-color-on-danger: #ffffff;
  --x-color-on-danger-shade: #ffffff;

  --x-color-gray-100: #000000;
  --x-color-on-gray-100: #ffffff;
  --x-color-gray-90: #1a1a1a;
  --x-color-on-gray-90: #ffffff;
  --x-color-gray-80: #333333;
  --x-color-on-gray-80: #ffffff;
  --x-color-gray-70: #4d4d4d;
  --x-color-on-gray-70: #ffffff;
  --x-color-gray-60: #666666;
  --x-color-on-gray-60: #ffffff;
  --x-color-gray-50: #808080;
  --x-color-on-gray-50: #ffffff;
  --x-color-gray-40: #999999;
  --x-color-on-gray-40: #000000;
  --x-color-gray-30: #b3b3b3;
  --x-color-on-gray-30: #000000;
  --x-color-gray-20: #cccccc;
  --x-color-on-gray-20: #000000;
  --x-color-gray-10: #e6e6e6;
  --x-color-on-gray-10: #000000;
  --x-color-gray-0: #ffffff;
  --x-color-on-gray-0: #000000;

  // Brand Logos
  --x-brand-header-logo: url(../assets/brand-x/header-logo-mojix.svg);
  --x-brand-logo: url(../assets/brand-x/logo.svg);
  --x-brand-dots: url(../assets/brand-x/dots.svg);
  --x-brand-semi-dots: url(../assets/brand-x/semi-dots.svg);
  --x-brand-mojix-source: url(../assets/brand-x/mojix-source-brand.png);
  --x-brand-bkg: url(../assets/brand-x/gray-background.png);
  --x-brand-ytem-transparent: url(../assets/brand-x/mojix-dots.svg);

  // Imgs
  --x-img-loader: url(../assets/img-x/splash-animation.svg);
  --x-img-buddy: url(../assets/img-x/buddy.png);
  --x-img-no-image: url(../assets/img-x/no-image.svg);

  // Arrows
  --x-icon-caret-forward: url(../assets/icons-x/arrows/arrows-caret-forward.svg);
  --x-icon-caret-back: url(../assets/icons-x/arrows/arrows-caret-back.svg);
  --x-icon-caret-down: url(../assets/icons-x/arrows/arrows-caret-down.svg);
  --x-icon-caret-up: url(../assets/icons-x/arrows/arrows-caret-up.svg);
  --x-icon-caret-header-back: url(../assets/icons-x/arrows/arrows-header-back.svg);
  --x-icon-refresh: url(../assets/icons-x/arrows/arrows-refresh.svg);

  // Shapes
  --x-icon-triangle-back: url(../assets/icons-x/shapes/shapes-triangle-back.svg);
  --x-icon-triangle-down: url(../assets/icons-x/shapes/shapes-triangle-down.svg);
  --x-icon-triangle-up: url(../assets/icons-x/shapes/shapes-triangle-up.svg);
  --x-icon-initializing: url(../assets/icons-x/shapes/shapes-semicircle.svg);
  --x-icon-semicircle-information: url(../assets/icons-x/shapes/shapes-semicircle-information.svg);
  --x-icon-circle-check: url(../assets/icons-x/shapes/shapes-circle-check.svg);
  --x-icon-triangle-alert: url(../assets/icons-x/shapes/shapes-triangle-alert.svg);
  --x-icon-point: url(../assets/icons-x/shapes/shapes-point.svg);
  --x-icon-circle-remove: url(../assets/icons-x/shapes/shapes-circle-remove.svg);
  --x-icon-md-remaining: url(../assets/icons-x/shapes/shapes-remaining.svg);
  --x-icon-md-found: url(../assets/icons-x/shapes/shapes-found.svg);
  --x-icon-md-added: url(../assets/icons-x/shapes/shapes-circle-added.svg);
  --x-icon-md-warning: url(../assets/icons-x/shapes/shapes-circle-warning.svg);

  // Globals
  --x-icon-offline: url(../assets/icons-x/globals/globals-offline.svg);
  --x-icon-notification: url(../assets/icons-x/globals/globals-notification.svg);
  --x-icon-search: url(../assets/icons-x/globals/globals-search.svg);
  --x-icon-filters: url(../assets/icons-x/globals/global-filters.svg);
  --x-icon-lock-closed: url(../assets/icons-x/globals/globals-lock-closed.svg);
  --x-icon-lock-open: url(../assets/icons-x/globals/globals-lock-open.svg);
  --x-icon-location: url(../assets/icons-x/globals/globals-location.svg);
  --x-icon-size: url(../assets/icons-x/globals/globals-size.svg);
  --x-icon-colors: url(../assets/icons-x/globals/globals-colors.svg);
  --x-icon-recommendations: url(../assets/icons-x/globals/globals-recommendations.svg);
  --x-icon-pointer: url(../assets/icons-x/globals/globals-pointer.svg);
  --x-icon-warning: url(../assets/icons-x/globals/global-warning.svg);
  --x-icon-reload-inventory: url(../assets/icons-x/globals/reload-inventory.svg);
  --x-icon-remove: url(../assets/icons-x/globals/globals-remove.svg);
  --x-icon-add: url(../assets/icons-x/globals/globals-add.svg);
  --x-icon-shopping-cart: url(../assets/icons-x/globals/globals-shopping-cart.svg);
  --x-icon-logout: url(../assets/icons-x/globals/globals-logout.svg);
  --x-icon-no-image: url(../assets/icons-x/globals/globals-no-image.svg);
  --x-loader-image-animated: url(../assets/icons-x/globals/global_loading.svg);
  --x-department: url(../assets/icons-x/globals/global-department.svg);
  --x-icon-send: url(../assets/icons-x/globals/globals-send.svg);
  --x-icon-in-progress: url(../assets/icons-x/globals/globals-in-progress.svg);
  --x-icon-local-in-progress: url(../assets/icons-x/globals/globals-local-in-progress.svg);
  --x-icon-person: url(../assets/icons-x/globals/globals-person.svg);
  --x-icon-calendar: url(../assets/icons-x/globals/globals-calendar.svg);
  --x-icon-keyboard: url(../assets/icons-x/globals/globals-keyboard.svg);
  --x-icon-checkmark-circle: url(../assets/icons-x/globals/globals-checkmark-circle.svg);
  --x-icon-warning-circle: url(../assets/icons-x/globals/globals-warning-circle.svg);
  --x-icon-error-warning: url(../assets/icons-x/globals/globals-circle-triangle.svg);
  --x-icon-error-circle: url(../assets/icons-x/globals/globals-error-circle.svg);
  --x-icon-single: url(../assets/icons-x/globals/single.svg);
  --x-icon-bulk: url(../assets/icons-x/globals/bulk.svg);
  --x-icon-dump: url(../assets/icons-x/globals/globals-dump.svg);
  --x-icon-damaged_repair: url(../assets/icons-x/globals/global_damaged_repair.svg);
  --x-icon-identify_repair: url(../assets/icons-x/globals/global_identify_repair.svg);
  --x-icon-missing_repair: url(../assets/icons-x/globals/global_missing_repair.svg);
  --x-icon-ok_repair: url(../assets/icons-x/globals/global_ok_repair.svg);
  --x-icon-simple-check: url(../assets/icons-x/globals/global-simple-check.svg);
  --x-icon-damaged-tag: url(../assets/icons-x/globals/globals-damaged-tag.svg);
  --x-icon-new-tag: url(../assets/icons-x/globals/globals-new-tag.svg);
  --x-icon-mates-colored: url(../assets/icons-x/globals/globals-mates-colored.svg);
  --x-icon-warning-colored: url(../assets/icons-x/globals/globals-warning-colored.svg);
  --x-icon-check-colored: url(../assets/icons-x/globals/globals-check-colored.svg);
  --x-icon-check-false-alarm: url(../assets/icons-x/globals/global-false-alarm.svg);
  --x-icon-check-thwarted-theft: url(../assets/icons-x/globals/global-thwarted-theft.svg);
  --x-icon-check-theft: url(../assets/icons-x/globals/global-theft.svg);
  --x-icon-scan: url(../assets/icons-x/globals/scan.svg);
  --x-icon-engine: url(../assets/icons-x/globals/engine.svg);
  --x-icon-history: url(../assets/icons-x/globals/history.svg);
  --x-icon-scan-information: url(../assets/icons-x/globals/scan-information.svg);
  --x-icon-scan-simulated-information: url(../assets/icons-x/globals/scan-simulated-information.png);
  --x-icon-information-avatar: url(../assets/icons-x/globals/information-avatar.svg);
  --x-icon-information-avatar-2: url(../assets/icons-x/globals/information-avatar-2.svg);
  --x-icon-information-avatar-3: url(../assets/icons-x/globals/information-avatar-3.svg);
  --x-icon-eye: url(../assets/icons-x/globals/eye.svg);
  --x-icon-eye-off: url(../assets/icons-x/globals/eye-off.svg);
  --x-icon-data-matrix: url(../assets/icons-x/globals/data-matrix.svg);

  // Reader
  --x-icon-qr-code: url(../assets/icons-x/reader/reader-qr_code.svg);
  --x-icon-flash: url(../assets/icons-x/reader/reader-flash.svg);
  --x-icon-reader-connected: url(../assets/icons-x/reader/reader-connected.svg);
  --x-icon-reader-disconnected: url(../assets/icons-x/reader/reader-disconnected.svg);
  --x-icon-reader-scanning: url(../assets/icons-x/reader/reader-scanning.svg);
  --x-icon-ellipse: url(../assets/icons-x/reader/ellipse.svg);
  --x-icon-close: url(../assets/icons-x/globals/close.svg);
  --x-icon-battery-100: url(../assets/icons-x/reader/battery-100.svg);
  --x-icon-battery-75: url(../assets/icons-x/reader/battery-75.svg);
  --x-icon-battery-50: url(../assets/icons-x/reader/battery-50.svg);
  --x-icon-battery-25: url(../assets/icons-x/reader/battery-25.svg);
  --x-icon-battery-0: url(../assets/icons-x/reader/battery-0.svg);
  --x-icon-min-power: url(../assets/icons-x/reader/min_power.svg);
  --x-icon-max-power: url(../assets/icons-x/reader/max_power.svg);
  --x-icon-min-sound: url(../assets/icons-x/reader/sound-min.svg);
  --x-icon-max-sound: url(../assets/icons-x/reader/sound-max.svg);
  --x-icon-information-fill: url(../assets/icons-x/reader/information.svg);
  --x-icon-bluetooth: url(../assets/icons-x/reader/bluetooth.svg);
  --x-icon-barcode: url(../assets/icons-x/reader/barcode.svg);
  --x-icon-camera: url(../assets/icons-x/reader/camera.svg);
  --x-icon-reader-embed: url(../assets/icons-x/reader/reader-embed.svg);
  --x-icon-reader-generic: url(../assets/icons-x/reader/reader-generic.svg);
  --x-icon-reader-silenced: url(../assets/icons-x/reader/reader-silenced.svg);

  //Lists
  --x-icon-found: url(../assets/icons-x/lists/found.svg);
  --x-icon-mate: url(../assets/icons-x/lists/mate.svg);

  // Modules
  --x-icon-inventory-management: url(../assets/icons-x/modules/modules-inventory-management.svg);
  --x-icon-orders-management: url(../assets/icons-x/modules/modules-orders-management.svg);
  --x-icon-information: url(../assets/icons-x/modules/modules-information.svg);
  --x-icon-tasks: url(../assets/icons-x/modules/modules-tasks.svg);
  --x-icon-check: url(../assets/icons-x/modules/modules-check-icon.svg);
  --x-icon-settings: url(../assets/icons-x/modules/modules-settings.svg);
  --x-icon-anti-theft: url(../assets/icons-x/modules/modules-anti-theft.svg);
  --x-icon-edit-items: url(../assets/icons-x/modules/modules-edit-items.svg);
  --x-icon-find-it: url(../assets/icons-x/modules/modules-find-it.svg);
  --x-icon-inventory: url(../assets/icons-x/modules/modules-inventory.svg);
  --x-icon-my-stock: url(../assets/icons-x/modules/modules-my-stock.svg);
  --x-icon-pack: url(../assets/icons-x/modules/modules-pack.svg);
  --x-icon-partial-case: url(../assets/icons-x/modules/modules-partial-case.svg);
  --x-icon-pick: url(../assets/icons-x/modules/modules-pick.svg);
  --x-icon-receive: url(../assets/icons-x/modules/modules-receive.svg);
  --x-icon-repair: url(../assets/icons-x/modules/modules-repair.svg);
  --x-icon-replace: url(../assets/icons-x/modules/modules-replace.svg);
  --x-icon-ship: url(../assets/icons-x/modules/modules-ship.svg);
  --x-icon-commission: url(../assets/icons-x/modules/modules-commission.svg);
  --x-icon-fast-receive: url(../assets/icons-x/modules/modules-fast-receive.svg);
  --x-icon-restock: url(../assets/icons-x/modules/modules-restock.svg);
  --x-icon-restock-display: url(../assets/icons-x/modules/modules-restock-display.svg);
  --x-icon-check-display: url(../assets/icons-x/modules/modules-check-display.svg);
  --x-icon-manage-display: url(../assets/icons-x/modules/modules-manage-display.svg);
  --x-icon-relabel: url(../assets/icons-x/modules/modules-relabel.svg);
  --x-icon-encode: url(../assets/icons-x/modules/modules-encode.svg);
  --x-icon-associate: url(../assets/icons-x/modules/modules-associate.svg);
  --x-icon-unassociate: url(../assets/icons-x/modules/modules-unassociate.svg);
  --x-icon-lookup: url(../assets/icons-x/modules/modules-lookup.svg);
  --x-icon-replenishment: url(../assets/icons-x/modules/modules-replenishment.svg);
  --x-icon-search-menu: url(../assets/icons-x/modules/modules-search.svg);

  //OAuth
  --x-icon-google-icon: url(../assets/icons-x/oauth/google-icon.svg);
  --x-icon-github-icon: url(../assets/icons-x/oauth/github-icon.svg);
}
