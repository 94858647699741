ion-modal.modal-sheet {
  &::part(content) {
    width: 100% !important;
    height: 100% !important;
    --background: translarent;
  }
  &::part(backdrop) {
    // Makes the modal backdrop to overlap in case multiple sheets are shown on top of each other.
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  }
}
