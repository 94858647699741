.generic-list-custom-history {
  img {
    border-radius: 15px !important;
  }

  .end-centered-container {
    display: none !important;
  }
  generic-icon {
    width: 30px !important;
    height: 30px !important;
  }
}

.generic-option-item-tracker-home {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
  overflow-y: visible;

  .texts-container {
    max-width: 60%;
  }

  generic-option-item {
    max-height: 51px;
  }

  .end-container {
    max-width: calc(45% - 30px) !important;

    span {
      font-size: 12px;
    }
  }
}

.chip-primary {
  .chip-container {
    color: var(--x-color-accent) !important;
  }
}

.app-info-brand-container {
  generic-icon {
    color: var(--x-color-accent) !important;
  }
}
