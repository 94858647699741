ion-toast {
  --start: 0;
  --end: 0;
  --border-radius: 0;
  --height: unset;
  --max-height: unset;
  --min-height: unset;
  --background: transparent;
  --box-shadow: unset;
}

.toast-wrapper {
  &.toast-bottom {
    bottom: 46px;
  }
  &.toast-top {
    top: 70px;
  }
}

ion-toast {
  &::part(container) {
    margin: 10px;
    border-radius: 18px;
    background-color: var(--x-color-surface);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  }

  &::part(message) {
    color: var(--x-color-on-gray-10);
    position: relative;
    margin-right: 25px;
    &::before {
      content: "";
      position: absolute;
      right: -25px;
      top: 50%;
      transform: translateY(-50%);
      background-size: cover;
      height: 25px;
      width: 25px;
    }
  }

  &.success {
    &::part(container) {
      background-color: var(--x-color-surface);
      box-shadow: inset 0 0 0 5px var(--x-color-success), 0 0 20px 0 rgba(0, 0, 0, 0.5);
    }
    &::part(message) {
      color: var(--x-color-on-surface);
      &::before {
        background: var(--x-color-success);
        mask: var(--x-icon-checkmark-circle);
        mask-size: cover;
      }
    }
  }

  &.warning {
    &::part(container) {
      background-color: var(--x-color-surface);
      box-shadow: inset 0 0 0 5px var(--x-color-warning), 0 0 20px 0 rgba(0, 0, 0, 0.5);
    }
    &::part(message) {
      color: var(--x-color-on-surface);
      &::before {
        background: var(--x-color-warning);
        mask: var(--x-icon-warning-circle);
        mask-size: cover;
      }
    }
  }

  &.danger {
    &::part(container) {
      background-color: var(--x-color-surface);
      box-shadow: inset 0 0 0 5px var(--x-color-danger), 0 0 20px 0 rgba(0, 0, 0, 0.5);
    }
    &::part(message) {
      color: var(--x-color-on-surface);
      &::before {
        background: var(--x-color-danger);
        mask: var(--x-icon-error-warning);
        mask-size: cover;
      }
    }
  }

  &::part(button) {
    font-size: 14px !important;
    text-transform: none;
    padding: 6px 12px;
    border-radius: 10px;
    margin: 0 15px 0 7px;
    background-color: var(--x-color-gray-10);
    color: var(--x-color-on-gray-10);
    min-height: 30px;
    height: 30px;
  }
}
