ion-app {
  background-color: var(--x-color-on-surface);
  display: flex;
  flex-direction: column;
  ion-router-outlet {
    height: 0;
    flex-grow: 1; // Required for app-network to push content
    position: relative;
  }
}
